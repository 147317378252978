import React from "react";
import { withStyles } from "@material-ui/core";
import atolyeImg from "../../../images/surdur/dongu/atolye.jpg";
import kiralaImg from "../../../images/surdur/dongu/kirala.jpg";
import bisikletImg from "../../../images/surdur/dongu/bisiklet.jpg";
import firsatImg from "../../../images/surdur/dongu/firsat.jpg";
import mobileAppImg from "../../../images/surdur/dongu/mobileapp.jpeg";
import TwoColumn from "../../../components/home/TwoColumn";
import SubPageBase from "../../../components/SubPageBase";
import SecondChange from "../../../components/bizkimiz/SecondChange";

const styles = () => ({
  root: {
    fontSize: "1.5em"
  },
  content: {
    backgroundColor: "#EFF2F7",
    paddingBottom: "10em",

    "& div:first-child": {
      marginBottom: "2em"
    }

  }
});

const IndexPage = ({ classes }) => (
  <SubPageBase title={"DÖNGÜSEL EKONOMİ"} img={atolyeImg} className={classes.root} objectPosition="top">
    <div className={classes.content}>
      <TwoColumn
        imageSrc={atolyeImg}
        header="Decathlon Atölye"
        text={
          <p>Spor ürününün ömrünü uzatacak bakım hizmetlerimizden faydalan. Tamir edilebilecek spor ürününü çöpe atma,
            atölyemizde tamir ettir.<br />
            <a href="https://www.decathlon.com.tr/atolye-bakim.html">Daha
              fazlasını keşfet</a>
          </p>
        }
        textFirst={true}
      />

      <TwoColumn
        imageSrc={kiralaImg}
        header="Decathlon Kirala"
        text={
          <p>Yeni bir spora başlamak istiyorsun ancak sevip sevmeyeceğinden emin değil misin? Ya da ürünü yılın sadece
            belirli dönemlerinde mi kullanabiliyorsun? Kiralama hizmetimizden faydalanabilirsin.<br />
            <a href="https://www.decathlon.com.tr/kiralama-hizmeti.html">Daha
              fazlasını keşfet</a>
          </p>
        }
      />
      <SecondChange/>
      <TwoColumn
        imageSrc={bisikletImg}
        header="Bisiklet Programı"
        text={
          <p>
            Ömür boyu garantili Btwin bisikletler, çocuğunuza artık küçük geliyor olsa da başka bir çocuk tarafından
            kullanılabilir. Küçülen çocuk bisikletlerini sizden satın alıyor, yeniden kullanıma kazandırıyoruz.
            <br />
            <a href="https://www.decathlon.com.tr/ikinci-sans-bisiklet-programi.html">Daha fazlasını keşfet</a>
          </p>
        }
      />
      <TwoColumn
        imageSrc={mobileAppImg}
        header="Mobil Uygulama"
        textFirst={true}
        text={
          <p>
            Kullanmadığın spor ürününü Decathlon 2. şans mobil uygulaması üzerinden başka bir sporsevere sat, yeniden
            kullanıma kazandır! Kendin için de yeni bir spor keşfet.
            <br />
            <a href="https://www.decathlon.com.tr/ikinci-sans-mobil-uygulama.html">Daha fazlasını keşfet</a>
          </p>
        }
      />
      <TwoColumn
        imageSrc={firsatImg}
        header="Fırsat Köşesi"
        textFirst={true}
        text={
          <p>
            Spor için tasarlanıp üretilen ama mağazamızdaki raflarda yerini alana kadar yüzeysel hasarlar almış
            ürünlerimize ikinci bir şans veriyoruz.
          </p>
        }
      />
    </div>
  </SubPageBase>
);

export default withStyles(styles)(IndexPage);
