import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import img from "../../images/home/cantali-bisiklet.png";
import secondImg from "../../images/surdur/dongu/secondchance.png";

const styles = () => ({
  root: {
    color: "#fff",
    backgroundImage: `url('${img}')`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "10vw 15vw 10vw 15vw",
    textAlign: "center",
    fontSize: "6.55vw",
  },

});
const Decathlonluyuz = ({ classes }) => (
  <div className={classes.root}>
    <img src={secondImg} height='200px' alt='decathlon ikinci sans'/>
  </div>
);

export default withStyles(styles)(Decathlonluyuz);
